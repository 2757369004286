window.addEventListener('load', function () {
  const displayTextTh = {
    modal_cookie_consent: {
      title:
        '<div class="ot-pc-logo" role="img" aria-label="โลโก้บริษัท"><img alt="โลโก้บริษัท" src="https://investor.scgpackaging.com/storage/content/scgp-colorful.svg"></div>การตั้งค่าความเป็นส่วนตัว'
    }
  }

  const displayTextEn = {
    modal_cookie_consent: {
      title:
        '<div class="ot-pc-logo" role="img" aria-label="Company Logo"><img alt="Company Logo" src="https://investor.scgpackaging.com/storage/content/scgp-colorful.svg"></div>Privacy Preference Center'
    }
  }

  // Determine the language to use
  const displayText = window.reddotApiConfigs.lang === 'en' ? displayTextEn : displayTextTh

  setTimeout(function () {
    // Select the reddot element
    const reddotElement = document.getElementById('reddot')

    // Create a MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.type === 'childList' || mutation.type === 'subtree') {
          const titleElement = document.querySelector('.cm-header > .title')
          if (titleElement && titleElement.innerHTML !== displayText.modal_cookie_consent.title) {
            // Update modal cookie consent title
            titleElement.innerHTML = displayText.modal_cookie_consent.title
          }
          const linkElement = document.querySelector('#reddot > div > div > div.cm-modal.cm-reddot > div.cm-footer > p > a')
          if (linkElement) {
            linkElement.textContent = 'SCG PACKAGING'
            linkElement.setAttribute('href', 'https://www.scgpackaging.com/th')
          }
        }
      })
    })

    // Start observing the reddot element for changes
    if (reddotElement) {
      observer.observe(reddotElement, {
        childList: true, // Monitor changes to the direct children
        subtree: true // Monitor changes to all descendants
      })
    }

    // Show the cookie consent window
    reddotElement.style.display = 'block'
  }, 0)
})
