// plugins/laravel-echo.js

import Echo from 'laravel-echo'

export default ({ app }, inject) => {
  // Load Pusher
  window.Pusher = require('pusher-js')
  // Instantiate Echo
  const echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.PUSHER_APP_KEY,
    cluster: process.env.PUSHER_APP_CLUSTER,
    encrypted: true
  })

  echo.connector.pusher.connection.bind('connected', () => {
    // console.log('Pusher connection established! success')
    // ตัวอย่างการ subscribe channel และ listen event ที่นี่
  })

  echo.connector.pusher.connection.bind('failed', (error) => {
    console.error('Pusher connection failed:', error)
  })

  echo.connect()

  // Inject Echo instance
  inject('echo', echo)
}
