// import { auth } from '@/services/fireinit.js'
import { getAuth } from 'firebase/auth'

export default (context) => {
  const { store } = context
  const auth = getAuth()
  if (process.client) {
    return new Promise((resolve, reject) => {
      auth.onAuthStateChanged((user) => {
        // console.log('fire authhh', user)
        if (user) {
          store.state.firebaseUser.authUser = user
          return resolve(store.commit('firebaseUser/SET_AUTH_USER', user))
        } else {
          store.dispatch('firebaseUser/signout')
          const backUrl = localStorage.getItem('back_url')
          if (backUrl) {
            localStorage.removeItem('back_url')
            context.redirect(backUrl)
          }
          // else {
          //   context.redirect('/auth/login-via-phone')
          // }
        }
        resolve()
      })
    })
  }
}
