/* eslint-disable */

export default ({
  app
}) => {
  (function(g,d,o){
    g._ltq=g._ltq||[];g._lt=g._lt||function(){g._ltq.push(arguments)};
    var h=location.protocol==='https:'?'https://d.line-scdn.net':'http://d.line-cdn.net';
    var s=d.createElement('script');s.async=1;
    s.src=o||h+'/n/line_tag/public/release/v1/lt.js';
    var t=d.getElementsByTagName('script')[0];t.parentNode.insertBefore(s,t);
      })(window, document);
  _lt('init', {
    customerType: 'account',
    tagId: '6dc16021-7b36-4d39-ad54-d8acc9488383'
  });
  _lt('send', 'pv', ['6dc16021-7b36-4d39-ad54-d8acc9488383']);
}
