/* eslint-disable */

export default ({
  app
}) => {
    window._mfq = window._mfq || [];
    (function () {
      if (process.env.NODE_ENV === 'production') {
        var mf = document.createElement("script");
        mf.type = "text/javascript";
        mf.defer = true;
        mf.src = "//cdn.mouseflow.com/projects/9d50d8af-c214-489e-9d61-12da5ff285ac.js";
        document.getElementsByTagName("head")[0].appendChild(mf);
      }
    })()
  
}
